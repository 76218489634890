import { createStore } from 'vuex';
import precosStore from './precos';
import historicoStore from './historico';

const store = createStore({
    modules: {
        historico: historicoStore,
        precos: precosStore,
    },
    state() {
        return {
            todosProdutos: null,
            codigoInclusao: null,
            numeroSync: 0,
            sugestao: null,
            tipoTarefa: '',
            reprovacoes: [],
        };
    },
    mutations: {
        setTodosProdutos(state, payload) {
            state.todosProdutos = payload;
        },
        setSugestao(state, payload) {
            state.sugestao = payload;
        },
        setCodigoInclusao(state, payload) {
            state.codigoInclusao = payload;
        },
        plusNumeroSync(state) {
            state.numeroSync++;
        },
        minusNumeroSync(state) {
            state.numeroSync--;
        },
        resetNumeroSync(state) {
            state.numeroSync = 0;
        },
        setTipoTarefa(state, payload) {
            state.tipoTarefa = payload;
        },
        setReprovacoes(state, payload) {
            state.reprovacoes = payload;
        },
    },
    actions: {
        setTodosProdutos(context, payload) {
            context.commit('setTodosProdutos', payload);
        },
        setSugestao(context, payload) {
            context.commit('setSugestao', payload);
        },
        setCodigoInclusao(context, payload) {
            context.commit('setCodigoInclusao', payload);
        },
        plusNumeroSync(context) {
            context.commit('plusNumeroSync');
        },
        minusNumeroSync(context) {
            context.commit('minusNumeroSync');
        },
        resetNumeroSync(context) {
            context.commit('resetNumeroSync');
        },
        setTipoTarefa(context, payload) {
            context.commit('setTipoTarefa', payload);
        },
        setReprovacoes(context, payload) {
            context.commit('setReprovacoes', payload);
        },
    },
    getters: {
        getTodosProdutos(state) {
            return state.todosProdutos;
        },
        getNumerosSync(state) {
            let countEdit = JSON.parse(localStorage.getItem('inclusaoEdit'));
            let countTarefas = JSON.parse(localStorage.getItem('alertasValidade'));
            let countAuditoria = JSON.parse(localStorage.getItem('alertasAuditoria'));
            let countRecebimentos = JSON.parse(localStorage.getItem('alertasRecebimentos'));
            let countInclusao = JSON.parse(localStorage.getItem('inclusao'));
            let countPrecos = JSON.parse(localStorage.getItem('precos'));
            let countPrecosDelete = JSON.parse(localStorage.getItem('precosDelete'));
            let countPrecosEdit = JSON.parse(localStorage.getItem('precosEdit'));

            let numerosDivida = 0;

            if (countEdit) {
                numerosDivida += countEdit.length;
            }
            if (countTarefas) {
                numerosDivida += countTarefas.length;
            }
            if (countAuditoria) {
                numerosDivida += countAuditoria.length;
            }
            if (countRecebimentos) {
                numerosDivida += countRecebimentos.length;
            }
            if (countInclusao) {
                numerosDivida += countInclusao.length;
            }
            if (countPrecos) {
                numerosDivida += countPrecos.length;
            }
            if (countPrecosDelete) {
                numerosDivida += countPrecosDelete.length;
            }
            if (countPrecosEdit) {
                numerosDivida += countPrecosEdit.length;
            }

            let contagemFinal = numerosDivida + state.numeroSync - state.numeroSync;

            return contagemFinal;
        },
        getSugestao(state) {
            return state.sugestao;
        },
        getCodigoInclusao(state) {
            return state.codigoInclusao;
        },
        getTipoTarefa(state) {
            return state.tipoTarefa;
        },
        getReprovacoes(state) {
            return state.reprovacoes;
        },
    },
});

export default store;
