import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import IdleVue from 'idle-vue';

createApp(App)
    .use(store)
    .use(router)
    .use(IdleVue, {
        store,
        idleTime: 900000,
        startAtIdle: false,
    })
    .mount('#app');
