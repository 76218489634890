<template>
    <header v-if="title !== 'semHeader'">
        <div class="header">
            <transition name="fade">
                <a v-if="hasArrow" @click="$router.go(-1)" class="arrow-back">
                    <img src="./assets/img/arrow-back.svg" alt="refresh-image" />
                </a>
            </transition>
            <a id="logo" href="/carregamento">
                <img src="./assets/img/logo-minus.png" alt="logo-supermercado" />
            </a>
            <div class="menu">
                <strong>{{ title }}</strong>
            </div>
            <button id="btnRefresh" :class="{ 'refresh-none': hasNoRefresh }" type="button" @click="sincronizaDados">
                <img src="./assets/img/refresh.svg" alt="refresh-image" />
                <span id="attNumber" v-if="getNumerosSync > 0">{{ getNumerosSync }}</span>
            </button>
            <button v-if="Refresh" id="btnRefresh" type="button" @click="this.$router.push({ name: 'carregamento' })">
                <img src="./assets/img/refresh-white.svg" alt="refresh-image" />
            </button>
        </div>
    </header>

    <template v-if="isLogin">
        <header-principal :hasNoRefresh="true" titulo="Login"></header-principal>
    </template>

    <ModalIdle v-if="isIdle && !isLogin" />

    <mensagem-alerta @closeModal="closeModal" :mensagem="mensagem"></mensagem-alerta>

    <router-view v-slot="slotProps" v-if="!sincronizing">
        <transition name="slide" mode="out-in">
            <component :is="slotProps.Component"></component>
        </transition>
    </router-view>

    <tela-sync v-if="sincronizing" @closePopUp="closePopUp" :msgSync="msgSync"></tela-sync>

    <template v-if="!isSave && !isLogin">
        <footer v-if="title !== 'semHeader'">
            <div class="footer-logo">
                <img src="./assets/img/logo-minus.png" alt="relogio" />
            </div>
        </footer>
    </template>

    <div class="btn-save" v-if="isLogin">
        <button class="salvar" type="submit" form="formLogin">Entrar</button>
    </div>
</template>

<script>
import HTTP from './http/http.js';
import HeaderPrincipal from './components/HeaderPrincipal';
import MensagemAlerta from './components/MensagemAlerta.vue';
import TelaSync from './components/TelaSync.vue';
import ModalIdle from './components/ModalIdle.vue';

export default {
    components: {
        MensagemAlerta,
        HeaderPrincipal,
        TelaSync,
        ModalIdle,
    },
    data() {
        return {
            title: 'Valid',
            hasArrow: false,
            hasNoRefresh: false,
            isSave: false,
            mensagem: {},
            isLogin: false,
            sincronizing: false,
            counter: 0,
            msgSync: 'Sincronizando... Por favor, aguarde.',
            Refresh: false,
        };
    },
    computed: {
        getNumerosSync() {
            return this.$store.getters.getNumerosSync;
        },

        isIdle() {
            return this.$store.state.idleVue.isIdle;
        },
    },
    methods: {
        sincronizaDados() {
            if (navigator.onLine) {
                this.sincronizing = true;

                this.enviaInclusao();

                this.enviaPrecos();

                this.enviaPrecosDelete();

                this.enviaPrecosEdit();

                this.enviaEditInclusao();

                this.enviaTarefas();

                this.enviaAuditoria();

                this.enviaRecebimentos();

                setTimeout(() => {
                    this.sincronizing = false;

                    if (this.counter > 0) {
                        this.mensagem = {
                            isSend: true,
                            body: `${this.counter} sincronizações geraram erro. Certifique sua internet e sincronize novamente. `,
                            error: true,
                        };
                    } else {
                        this.mensagem = {
                            isSend: true,
                            body: 'Dados sincronizados com sucesso!',
                            error: false,
                        };
                    }

                    setTimeout(() => {
                        this.mensagem.isSend = false;
                        this.counter = 0;
                    }, 3000);
                }, 4000);
            } else {
                this.mensagem = {
                    isSend: true,
                    body: 'Você precisa estar conectado a internet.',
                    error: true,
                };
            }
        },

        closePopUp() {
            this.sincronizing = false;
        },

        enviaInclusao() {
            let produtosSalvos = JSON.parse(localStorage.getItem('inclusao'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('inclusao');

            let produtosNaoEnviados = [];

            if (produtosSalvos) {
                produtosSalvos.forEach((data) => {
                    HTTP.post(`${loja}/inclusao`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('inclusao', dataToLS);
                        });
                });
            }
        },

        enviaPrecosDelete() {
            let precosSalvos = JSON.parse(localStorage.getItem('precosDelete'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('precosDelete');

            let produtosNaoEnviados = [];

            if (precosSalvos) {
                precosSalvos.forEach((valor) => {
                    HTTP.delete(`${loja}/admin/precos`, { data: valor })
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(valor);
                            let dataToLS = JSON.stringify(produtosNaoEnviados);
                            localStorage.setItem('precosDelete', dataToLS);
                        });
                });
            }
        },

        enviaPrecosEdit() {
            let precosSalvos = JSON.parse(localStorage.getItem('precosEdit'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('precosEdit');

            let produtosNaoEnviados = [];

            if (precosSalvos) {
                precosSalvos.forEach((data) => {
                    HTTP.patch(`${loja}/admin/precos`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('precosEdit', dataToLS);
                        });
                });
            }
        },

        enviaPrecos() {
            let precosSalvos = JSON.parse(localStorage.getItem('precos'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('precos');

            let produtosNaoEnviados = [];

            if (precosSalvos) {
                precosSalvos.forEach((data) => {
                    HTTP.post(`${loja}/admin/precos`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('precos', dataToLS);
                        });
                });
            }
        },

        enviaEditInclusao() {
            let produtosEditados = JSON.parse(localStorage.getItem('inclusaoEdit'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('inclusaoEdit');

            let produtosNaoEnviados = [];

            if (produtosEditados) {
                produtosEditados.forEach((data) => {
                    HTTP.patch(`${loja}/inclusao`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('inclusaoEdit', dataToLS);
                        });
                });
            }
        },

        enviaTarefas() {
            let tarefasSalvos = JSON.parse(localStorage.getItem('alertasValidade'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('alertasValidade');

            let produtosNaoEnviados = [];

            if (tarefasSalvos) {
                tarefasSalvos.forEach((data) => {
                    HTTP.patch(`${loja}/tarefas`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('alertasValidade', dataToLS);
                        });
                });
            }
        },

        enviaAuditoria() {
            let tarefasSalvos = JSON.parse(localStorage.getItem('alertasAuditoria'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('alertasAuditoria');

            let produtosNaoEnviados = [];

            if (tarefasSalvos) {
                tarefasSalvos.forEach((data) => {
                    HTTP.patch(`${loja}/admin/tarefas`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('alertasAuditoria', dataToLS);
                        });
                });
            }
        },

        enviaRecebimentos() {
            let tarefasSalvos = JSON.parse(localStorage.getItem('alertasRecebimentos'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('alertasRecebimentos');

            let produtosNaoEnviados = [];

            if (tarefasSalvos) {
                tarefasSalvos.forEach((data) => {
                    HTTP.patch(`${loja}/admin/recebimentos`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('alertasRecebimentos', dataToLS);
                        });
                });
            }
        },

        closeModal() {
            this.mensagem.isSend = false;
        },
    },
    watch: {
        $route: function (val) {
            this.title = val.meta.title;
            this.hasArrow = val.meta.hasArrow;
            this.hasNoRefresh = val.meta.hasNoRefresh;
            this.Refresh = val.meta.Refresh;
            this.isSave = val.meta.isSave;
            this.isLogin = val.meta.isLogin;
        },
    },
};
</script>

<style>
:root {
    --verde: #56bb8f;
    --vermelho: #ef6969;
    --branco: #ffffff;
    --preto: #000;
    --cinza: #cdcdcd;
    --cinza-escuro: #5f6368;
    --cinza-claro: #f4f4f4;
    --azul-escuro: #0f1628;
}

* {
    /* font-family: 'Karla', sans-serif; */
    font-family: 'Arial';
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    font-weight: 400;
}

body {
    width: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
    overscroll-behavior-y: contain;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-100vw);
}

.slide-enter-to,
.slide-leave-from {
    transform: translateX(0px);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.4s ease-in-out;
}

.slide-both-enter-from {
    transform: translateX(-100vw);
}

.slide-both-leave-to {
    transform: translateX(100vw);
}

.slide-both-enter-to,
.slide-both-leave-from {
    transform: translateX(0px);
}

.slide-both-enter-active,
.slide-both-leave-active {
    transition: all 0.4s ease-in-out;
}

header {
    width: 100%;
    display: flex;
    min-height: 56px;
    /* position: fixed;
    top: 0; */
    background-color: #56bb8f;
    z-index: 2;
}

.header {
    width: 100%;
    align-items: center;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--cinza);
}

.header p {
    font-size: 16px;
}

.header button a {
    background-color: #fff;
}

#logo {
    /* display: flex; */
    display: none;
}

#logo img {
    width: 70px;
}

.menu strong {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
}

.header a img {
    width: 25px;
}

.header button {
    cursor: pointer;
    border: none;
}

.arrow-back {
    cursor: pointer;
    display: flex;
    align-items: center;
}

footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--vermelho);
    box-shadow: 0 0 5px #cdcdcd;
}

.footer-logo {
    width: 100%;
    position: fixed;
    display: flex;
    bottom: 0;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 5px #a8a8a8;
    place-items: center;
    place-content: center;
}

footer div {
    display: flex;
}

footer img {
    width: 70px;
}

.format-footer {
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
}

.format-footer h3 {
    font-size: 15px;
    margin-top: 5px;
    color: var(--branco);
    font-weight: 500;
}

#btnRefresh {
    position: absolute;
    right: 25px;
    background-color: transparent;
    display: flex;
}

#btnRefresh:hover {
    cursor: pointer;
}

#btnRefresh img {
    width: 35px;
}

#btnRefresh.refresh-none {
    display: none;
}

#attNumber {
    border-radius: 30%;
    position: absolute;
    bottom: 3px;
    font-size: 10px;
    right: 3px;
    background-color: #ef6969;
    color: #fff;
    padding: 3px;
}

.btn-save {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 0 5px #cdcdcd;
    justify-content: flex-end;
}

.salvar {
    cursor: pointer;
    min-width: 64px;
    border: none;
    padding: 20px 45px;
    background-color: #fff;
    color: var(--verde);
    font-weight: 600;
    font-size: 20px;
}

.salvar:hover {
    box-shadow: none;
    background-color: var(--azul-escuro);
    color: #fff;
}

@media (max-width: 319px) {
    .header p {
        font-size: 14px;
    }

    #logo img {
        width: 50px;
    }

    .header a img {
        width: 22px;
    }

    #btnRefresh img {
        width: 22px;
    }
}

@media (max-width: 300px) {
    #logo {
        display: none;
    }
}
</style>
