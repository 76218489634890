import { createRouter, createWebHistory } from 'vue-router';

const TelaCarregamento = () => import('../views/TelaCarregamento.vue');
const TelaLoginManager = () => import('../views/TelaLoginManager.vue');
const TelaMenu = () => import('../views/gestor/TelaMenu.vue');
const MenuComercial = () => import('../views/comercial/MenuComercial.vue');
const TelaPedidos = () => import('../views/gestor/TelaPedidos.vue');
const PedidosComercial = () => import('../views/comercial/PedidosComercial.vue');
const PedidosHistorico = () => import('../views/comercial/PedidosHistorico.vue');
const PedidosPreco = () => import('../views/gestor/PedidosPreco.vue');
const VerificaPedido = () => import('../views/comercial/VerificaPedidoComercial.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: TelaLoginManager,
        meta: {
            title: 'semHeader',
            hasArrow: false,
            hasNoRefresh: true,
            isLogin: true,
        },
    },
    {
        path: '/menu/comercial',
        name: 'menu.comercial',
        component: MenuComercial,
        meta: {
            title: '',
            hasArrow: false,
            hasNoRefresh: true,
        },
    },
    {
        path: '/pedidos/comercial',
        name: 'pedidos.comercial',
        component: PedidosComercial,
        meta: { title: 'Solicitações de Rebaixa para Aprovação', hasArrow: false, hasNoRefresh: true, Refresh: true },
    },
    {
        path: '/pedidos/historico',
        name: 'pedidos.historico',
        component: PedidosHistorico,
        meta: { title: '', hasArrow: true, hasNoRefresh: true },
    },
    {
        path: '/verifica/pedido',
        name: 'verifica.pedido',
        component: VerificaPedido,
        meta: { title: '', hasArrow: true, hasNoRefresh: true, isSave: true },
    },
    {
        path: '/carregamento',
        name: 'carregamento',
        component: TelaCarregamento,
        meta: { title: 'semHeader', hasArrow: false, hasNoRefresh: true },
    },
    {
        path: '/menu',
        name: 'menu',
        component: TelaMenu,
        meta: {
            title: 'Menu',
            hasArrow: false,
            hasNoRefresh: false,
        },
    },
    {
        path: '/pedidos',
        name: 'pedidos',
        component: TelaPedidos,
        meta: { title: 'Menu', hasArrow: false, hasNoRefresh: false },
    },
    {
        path: '/pedidos/preco',
        name: 'pedidos.preco',
        component: PedidosPreco,
        meta: { title: 'Preço Especial', hasArrow: true, hasNoRefresh: true, isSave: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    let currentRoute = to.name;

    if (currentRoute == 'login') {
        if (currentRoute == 'login') {
            next();
        } else {
            router.push({ name: 'login' });
        }
    } else if (currentRoute !== 'login') {
        let user = sessionStorage.getItem('usuario');

        if (user) {
            next();
        } else {
            router.push({ name: 'login' });
        }
    } else {
        next();
    }
});

export default router;
